/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Kevin",
  logo_name: "Kevin.Whitlock()",
  nickname: "Kevin",
  full_name: "Kevin Whitlock",
  subTitle: "Software Developer 🔥. Always learning.",
  resumeLink:
    "https://uoguelphca-my.sharepoint.com/:b:/g/personal/kwhitloc_uoguelph_ca/EUFdhF0Xdc1IlZo1hshQxgkBlbPkggQihtmLm6hJU-tAmQ?e=f3wSQp",
  mail: "mailto:kwhitloc@uoguelph.ca",
};

const socialMediaLinks = {
  /* Your Social Media Link */

  linkedin: "https://www.linkedin.com/in/kevin-james-whitlock/",
  gmail: "kevinwhitlock6@gmail.com",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for web and mobile applications",
        "⚡ Building responsive websites using ReactJS",
        "⚡ Creating application backend and REST-API's with Python",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },

        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Project Management",
      fileName: "BlogsImg",
      skills: [
        "⚡ Experience leading a team of developers",
        "⚡ Experience turning business requirements into user stories",
        "⚡ Experience with demoing projects to stakeholders",
      ],
      softwareSkills: [
        {
          skillName: "Jira",
          fontAwesomeClassname: "simple-icons:jirasoftware",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Excel",
          fontAwesomeClassname: "simple-icons:microsoftexcel",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:azuredevops",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Trello",
          fontAwesomeClassname: "simple-icons:trello",
          style: {
            color: "#FFCA28",
          },
        },

        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Guelph",
      subtitle: "Bachelors in Computer Science, Minor in Marketing",
      logo_path: "universtityofguelph.png",
      alt_name: "UofG",
      duration: "2018 - 2023",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, OOP, and Image Processing",

        "⚡ I have implemented several projects based on what I have learned under my Computer Science Courses ",
      ],
      website_link: "https://www.uoguelph.ca/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Hackathon",
      subtitle: "HackTheNorth",
      logo_path: "htn.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "htn",
      // color_code: "#2AAFED",
      color_code: "#47A048",
    },
    {
      title: "Hackathon",
      subtitle: "DeltaHacks",
      logo_path: "delta.png",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "hackathon",
      color_code: "#E2405F",
    },
    {
      title: "Hackathon",
      subtitle: "ConUHacks",
      logo_path: "conu.jpg",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "MongoDB University",
      // color_code: "#F6B808",
      color_code: "#47A048",
    },
    {
      title: "WorkShop",
      subtitle: "Google Flutter WorkShop",
      logo_path: "flutter.png",
      certificate_link:
        "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
    {
      title: "WorkShop",
      subtitle: "Android Dev Summit",
      logo_path: "and.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
      alt_name: "Google",
      color_code: "#fe0037",
    },

    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work | Freelance | Co-ops",
  description:
    "I have a wide variety of software development experience in multiple industries",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "React Developer",
          company: "NCR",
          company_url: "https://www.ncr.com/",
          logo_path: "ncrLogo.png",
          duration: "May 2023 - Feb 2024",
          location: "Work From Home",
          description:
            "  Designed and developed UI web and mobile features with ReactJS, React Native, and TypeScript for the Digital Banking team",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Work Experience (Co-ops)",
      experiences: [
        {
          title: "React Developer (Co-op)",
          company: "NCR",
          company_url: "https://www.ncr.com/",
          logo_path: "ncrLogo.png",
          duration: "Jan 2022 - Sept 2022",
          location: "Work From Home",
          description:
            "  Designed and developed UI features with ReactJS and TypeScript for the Digital Banking team",
          color: "#0071C5",
        },
        {
          title: "Full Stack Developer (Co-op)",
          company: "Ministry of Transportation, Ontario",
          company_url: "https://www.ontario.ca/page/ministry-transportation",
          logo_path: "mto.png",
          duration: "May 2021 - Sept 2021",
          location: "Work From Home",
          description:
            " Migrated crucial Java Web Applications from the Struts 1.0 to Struts 2.0 to improve efficiency and mitigate security flaws",
          color: "#0071C5",
        },
        {
          title: "Full Stack Developer (Co-op)",
          company: "PerkinElmer",
          company_url: "https://www.perkinelmer.com/",
          logo_path: "perk.png",
          duration: "Sept 2020 - Dec 2020",
          location: "Work From Home",
          description:
            " Developed data analytic tools to enhance the clinical data review process for medical reviewers using C#, Python, Spotfire and a JSON framework",
          color: "#0071C5",
        },
        {
          title: "Web Development (Co-op)",
          company: "University of Guelph (CCS)",
          company_url: "https://www.uoguelph.ca/ccs/",
          logo_path: "ccs.png",
          duration: "May 2020 - Sept 2020",
          location: "Work From Home",
          description:
            "Created an AODA compliant website for the newly created Institute of Research and Planning department using HTML, CSS, and JavaScript ",
          color: "#ee3c26",
        },
        {
          title: "Field Service Tech (Intern)",
          company: "Bell Canada",
          company_url: "https://www.bellsolutionstech.ca/",
          logo_path: "bell.jpg",
          duration: "May 2019 - Sept 2019",
          location: "Sarnia",
          description:
            "Independently installed Fiber Optic home phone, internet, and TV",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Freelance",
      experiences: [
        {
          title: "Lead Developer",
          company: "Getlayered.io",
          company_url: "https://getlayered.io/",
          logo_path: "gl.jpg",
          duration: "Oct 2021 - May 2022",
          location: "Work From Home",
          description:
            "Built and designed a B2B membership Web3 platform that allows users to make custom banners with NFTs they own",
          color: "#4285F4",
        },
        {
          title: "Lead Image Generation Developer",
          company: "illogics NFT",
          company_url: "https://www.illogics.io/",
          logo_path: "ill.png",
          duration: "Oct 2021 - May 2022",
          location: "Work From Home",
          description:
            "Created a random image generation system using theoretical probabilities and Chainlink VRF to create unique NFTs",
          color: "#4285F4",
        },
        {
          title: "Lead Developer",
          company: "DogeDash",
          company_url: "https://shop.dogedash.com/",
          logo_path: "doge.jpg",
          duration: "Dec 2020 - Oct 2021",
          location: "Work From Home",
          description:
            "Developed shop.dogedash.com a merchandise site for DogeDash coin",
          color: "#4285F4",
        },
        {
          title: "Lead Developer",
          company: "6ixMotorsports",
          company_url: "https://6ixmotorsports.ca/",
          logo_path: "6ixM.png",
          duration: "may 2021 - June 2021",
          location: "Work From Home",
          description:
            "Developed website to handle payments for the rentals of jet skis, cars, boats and jets.",
          color: "#4285F4",
        },
        {
          title: "Project Manager",
          company: "6ixBuzzTV",
          company_url: "https://6ix.buzz/",
          logo_path: "6ix.png",
          duration: "Dec 2020 - Present",
          location: "Work From Home",
          description:
            "Managed development of 6ix.buzz which receives 50,000 daily page views and 300,000 unique monthly viewers",
          color: "#4285F4",
        },
        {
          title: "Project Manager",
          company: "6ixNFTs",
          company_url: "https://6ix.buzz/",
          logo_path: "6ix.png",
          duration: "Dec 2020 - Present",
          location: "Work From Home",
          description:
            "Managed development 4 NFTs that generated 30 eth in revenue from bidding",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "When I'm not working, I also enjoy tinkering with different technologies to see what I can do with them. I've highlighted some of those below",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "Kevin.JPG",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/Harikrushn9",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Ethereum Wallet Collection Discord Bot",
      url: "/#/projects",
      description:
        "Created a Discord bot that would collecy and link discord usernames to Ethereum wallet addresses",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku",
        },
      ],
    },
    {
      id: "1",
      name: "Lottery Discord Bot",
      url: "/#/projects",
      description: "Created a lottery bot to assign whitelist spots to users",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku",
        },
      ],
    },
    {
      id: "2",
      name: "Image Saver Discord Bot",
      url: "https://github.com/KevinWhit/Image-Saver-DiscordBot",
      description:
        "A bot that will save specified images sent into a discord server and then upload it to Imgur and post the link in an image log channel",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku",
        },
      ],
    },
    {
      id: "3",
      name: "“DeBadWordify” Full-Stack Application ",
      url: "http://amicancelled.online/",
      description:
        "an application that allows a user to enter their twitter account and have anything that could be considered inappropriate classified.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "4",
      name: "“Homely” Android App",
      url: "https://devpost.com/software/homely-c42in8",
      description:
        "an app that geolocates the positions of homeless individuals in order to classify their needs and direct it towards charities.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "5",
      name: "“HealthPort” Website",
      url: "https://devpost.com/software/healthport",
      description:
        "web application that allowed users to book appointments and see their schedule, while receiving text notifications.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
