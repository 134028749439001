import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import { Fade } from "react-reveal";
import { projectsHeader, projects } from "../../portfolio.js";
import "./MyBlog.css";
import ProjectsImg from "./ProjectsImg";
import { style } from "glamor";

function Projects(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div className="projects-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <ProjectsImg theme={theme} />
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
                style={{ color: theme.text }}
              >
                {"Co-op Work Term Blog"}
              </h1>
              <p
                className="projects-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                As a part of my Co-op program at the University of Guelph, I am
                responsible for writing a report that outlines some of the
                interesting projects and things I have been able to learn over
                my Co-op terms.
              </p>
            </div>
          </div>
        </Fade>
      </div>

      <div className="mtoBlog">
        <h1>NCR</h1>
        <h2>React Developer</h2>

        <h3>Introduction</h3>
        <p
          className="projects-header-detail-text subTitle"
          style={{ color: theme.secondaryText }}
        >
          I'm headed into my last year studying Computer Science with a minor in
          Marketing at the University of Guelph. This is a recount of my fourth
          and fifth Co-op work term where I worked as a React Developer for NCR
          on the D3 Digital Banking Team.
        </p>

        <h3>About NCR and D3 Banking</h3>
        <p
          className="projects-header-detail-text subTitle"
          style={{ color: theme.secondaryText }}
        >
          NCR was company founded in 1884 and headquartered out of Atlanta,
          Georgia. They invented the electronic cash register, magnetic credit
          card strip, and self checkout machine. But they do much more creating
          hardware and software to help "Run the x" and guide the future of
          global commerce. The D3 Digital banking team creates software for
          banks and credit unions to offer their users digital banking
          experiences that compete with industry leaders.
        </p>

        <h3>What I Did</h3>
        <p
          className="projects-header-detail-text subTitle"
          style={{ color: theme.secondaryText }}
        >
          During my 8 months working at NCR I worked on the D3 Digital banking
          team where I was responsible for front-end bug fixes, UI feature
          requests, and assigned epics for Digital Banking Web Application. I
          worked with React and TypeScript on the large scale web application
          and interacted with the APIs built by the server team.
        </p>

        <h3>Goals</h3>
        <p
          className="projects-header-detail-text subTitle"
          style={{ color: theme.secondaryText }}
        >
          At the beginning of the work term I developed 3 goals about what I
          wanted to takeaway from the work experience. They were as follows:{" "}
          <br />
          1. My goal is to become proficient in typescript development with the
          ReactJS framework. <br />
          2. My goal is to become proficient at professional CSS styling, and be
          able to translate designs into styles effectively.
          <br />
          3. My goal is to learn how to successfully work in an environment
          where the UI work is completely by a separate team than the
          functionality.
          <br />
          And additionally 2 more goals at the 4 month mark, they were as
          follows: <br />
          4. My goal is to act as a good mentor to the new Co-op student.
          <br />
          5. My goal is to be able to implement full epics from start to finish
        </p>

        <h3>Learnings/Reflections</h3>
        <p
          className="projects-header-detail-text subTitle"
          style={{ color: theme.secondaryText }}
        >
          For my first goal I wanted to become proficient in typescript
          development with ReactJS. I feel that I accomplished this as I was
          able to successfully implement stories and bug fixes using this stack.
          I have a much better understanding of how it works and feel
          comfortable writing code with it.
          <br />
          <br />
          For my second goal I wanted to become proficient in CSS styling. I
          feel like I accomplished this as I was able to turn figma designs into
          responsive code using the MUI framework
          <br />
          <br />
          For my third goal I wanted to be successfully in an environment where
          the UI work was done by separate engineers then the server work. I
          feel like I accomplished this as I was able to identify when a change
          required server work and explain it in a way so that it could be
          completed by a server Dev and I could successfully do the UI portion.
          <br />
          <br />
          For my fourth goal I wanted to act as a good mentor for the new Co-op
          student. I felt as if I accomplished this as I was able to help them
          on board and get setup and answer any questions that they had. And if
          I didn't know the answer I could direct them to someone that would.
          <br />
          <br />
          For my fifth goal I wanted to be able to implement full epics from
          start to finish. I feel as if I accomplished this because I was
          assigned and able to implement epics from start to finish.
          <br />
          <br />
        </p>

        <h3>Conclusions</h3>
        <p
          className="projects-header-detail-text subTitle"
          style={{ color: theme.secondaryText }}
        >
          Having the opportunity to support and work on digital banking software
          for NCR was an amazing experience. I learned a variety of skills from
          technical to soft and I will be bringing what I learned and
          experienced throughout my career.
        </p>

        <h3>Acknowledgements</h3>
        <p
          className="projects-header-detail-text subTitle"
          style={{ color: theme.secondaryText }}
        >
          My experience everyday working for this company and team was an
          extremely positive experience and a large part of that was due to the
          team and the culture that I had the pleasure of working with. I want
          to thank my manager Mala, and my two mentors Jagruti and Rida that
          were there whenever I had any questions or doubts. And would like to
          thank everyone on the team that I had the pleasure of working with and
          learning from.
        </p>
      </div>

      <br />
      <br />

      <br />
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Projects;
